<template>
  <div>
    <h3>Time Boat注销协议</h3>
    <pre class="txt-info">
1.请您确保您是有权进行本注销操作的相关当事人。
2.您的本次注销操作，将该删除Time Boat帐号下的所有数据，所有设备会与您当前登录的账号解除绑定，并清除设备之前存储在服务端的数据，当前手机中，Time Boat的缓存数据也将被删除。
3.撤销授权还将撤销您对Time Boat APP用户协议和隐私政策的同意，并退出Time Boat APP应用。若您要重新使用Time Boat APP，需要您再次同意Time Boat APP用户协议及隐私政策并授权相关权限。
4.物理设备中存储的数据需要您进行人工本地物理重置后清除，若设备支持，您可直接在设备上进行重置或参照“隐私政策”中的撤销授权进行重置。上述数据撤销后均不可恢复。若要重新使用设备，需要您再次进行绑定并且重新授权。
    </pre>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
.txt-info {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
